import Axios from 'axios'
import Store from '@/store/store'
import { DateTime } from 'luxon'
import SyncDateTimesManager from '@/Lib/SyncDateTimesManager'
import SnakManager from '@/Lib/SnakManager'

export default {
  storageContainers: { // Local Storage Key names
    all: 'all', // this is only here to facilitate getting all data using getReferenceDataFromServer
    auditors: 'auditors',
    crewIds: 'crewids',
    customerProjectLinks: 'customerProjectLinks',
    failureReasons: 'failureReasons',
    foremen: 'foremen',
    inspectionAndTreatCodes: 'inspectionAndTreatCodes',
    itemDataSources: 'itemDataSources',
    itemDefinitions: 'itemDefinitions',
    itemFieldValues: 'itemFieldValues',
    itemGroupDefinitions: 'itemGroupDefinitions',
    itemGroupDefModel: 'itemGroupDefModel',
    jobInfos: 'jobInfos',
    levels: 'levels',
    models: 'models',
    preservatives: 'preservatives',
    profitCenters: 'profitCenters',
    qcDataSourceLocation: 'qcDataSourceLocation',
    // qcVersion: 'qcVersion',
    ratings: 'ratings',
    ratingTypes: 'ratingTypes',
    reasons: 'reasons',
    selectionTypes: 'selectionTypes',
    serviceCodes: 'serviceCodes',
    serviceTypes: 'serviceTypes',
    statuses: 'statuses',
    supervisors: 'supervisors',
    targets: 'targets',
    tasks: 'tasks',
    territories: 'territories',
    trucks: 'trucks',
    trailers: 'trailers',
    userAccessToken: 'userAccessToken',
    userAccount: 'userAccount',
    username: 'username',
    versions: 'versions',
    visitStates: 'visitStates',
    volatile: 'volatile',
    weightingFactors: 'weightingFactors',
  },
  // calls to get some reference data
  async getReferenceDataFromServer (refDataKey, lastSyncDateTime) {
    // console.log('Updating the Reference Data')
    const apiClient = Axios.create({
      withCredentials: false,
      headers: {
        Authorization: `Bearer ${Store.getters.authToken}`,
        dataType: 'json',
        contentType: 'application/json',
        crossDomain: true,
      },
    })

    const URL = Store.getters.azureProxyBaseURL + `FieldVue/referencedata?refdata=${this.storageContainers[refDataKey].toLowerCase()}&lastSyncDateTime=${lastSyncDateTime}`

    try {
      // get an instance of the syncDateTimesManager
      const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
      const result = await apiClient.get(URL)
      // get the current dateTime
      const currentDateTime = DateTime.local()

      if (refDataKey === 'volatile' || refDataKey === 'all') { // The refDataKey value 'all' also gets the volatile data so update those with the current date/time
        syncDateTimeManager.setSyncDateTime(refDataKey, { qcVersion: '0.0', lastSync: currentDateTime, duration: 240 })
        if (result.data.Auditors.length > 0) {
          Store.$app.$storage.set(this.storageContainers.auditors, result.data.Auditors, { compressData: true })
        }
        if (result.data.CustomerProjectLinks.length > 0) {
          Store.$app.$storage.set(this.storageContainers.customerProjectLinks, result.data.CustomerProjectLinks, { compressData: true })
        }
        if (result.data.Foremen.length > 0) {
          Store.$app.$storage.set(this.storageContainers.foremen, result.data.Foremen, { compressData: true })
        }
        if (result.data.JobInfos.length > 0) {
          Store.$app.$storage.set(this.storageContainers.jobInfos, result.data.JobInfos, { compressData: true })
        }
        if (result.data.Supervisors.length > 0) {
          Store.$app.$storage.set(this.storageContainers.supervisors, result.data.Supervisors, { compressData: true })
        }
      }
      if (refDataKey === 'all') {
        syncDateTimeManager.setSyncDateTime(this.storageContainers.all, { qcVersion: '0.0', lastSync: currentDateTime, duration: 20160 })

        Store.$app.$storage.set(this.storageContainers.auditors, result.data.Auditors, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.crewIds, result.data.CrewIDs, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.customerProjectLinks, result.data.CustomerProjectLinks, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.failureReasons, result.data.FailureReasons, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.foremen, result.data.Foremen, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.inspectionAndTreatCodes, result.data.InspectionAndTreatCodes, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.itemDataSources, result.data.ItemDataSources, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.itemDefinitions, result.data.ItemDefinitions, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.itemFieldValues, result.data.ItemFieldValues, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.itemGroupDefModel, result.data.ItemGroupDefModel, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.itemGroupDefinitions, result.data.ItemGroupDefinitions, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.jobInfos, result.data.JobInfos, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.levels, result.data.Levels, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.models, result.data.Models, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.profitCenters, result.data.ProfitCenters, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.ratingTypes, result.data.RatingTypes, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.ratings, result.data.Ratings, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.reasons, result.data.Reasons, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.selectionTypes, result.data.SelectionTypes, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.serviceCodes, result.data.ServiceCodes, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.serviceTypes, result.data.ServiceTypes, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.statuses, result.data.Statuses, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.supervisors, result.data.Supervisors, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.targets, result.data.Targets, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.tasks, result.data.Tasks, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.territories, result.data.Territories, { compressData: true })
        Store.$app.$storage.set(this.storageContainers.versions, result.data.Versions, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.visitStates, result.data.VisitStates, { compressData: false })
        Store.$app.$storage.set(this.storageContainers.weightingFactors, result.data.WeightingFactors, { compressData: false })

        // update the global state indicating all reference data is available
        Store.dispatch('updateRefDataIsAvailable', true)
      } else if (refDataKey === 'customerProjectLinks') {
        syncDateTimeManager.setSyncDateTime(refDataKey, { qcVersion: '0.0', lastSync: currentDateTime, duration: 480 })
        Store.$app.$storage.set(this.storageContainers.customerProjectLinks, result.data, { compressData: true })
      } else {
        syncDateTimeManager.setSyncDateTime(refDataKey, { qcVersion: '0.0', lastSync: currentDateTime, duration: 10080 })
        Store.$app.$storage.set(refDataKey, result.data, { compressData: true })
      }
      // console.log('Done Updating the Reference Data')
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'FieldVue',
        FeatureName: 'ReferenceDataManager.js - getReferenceDataFromServer()',
        ErrorCode: error.name,
        ErrorDescription: 'Failed to get Reference Data.',
        AdditionalDetails: `Message: ${error.message}. Stack: ${error.stack}`,
      })
    }
  },
  // calls to get reference data from ServiceNow
  async getReferenceDataFromServiceNow (refDataKey) {
    // console.log('Updating the Reference Data from ServiceNow')
    let url = ''
    let result = []
    // get an instance of the syncDateTimesManager
    const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
    // get the current dateTime
    const currentDateTime = DateTime.local()

    if (refDataKey.match(/^(trucks|trailers)$/)) {
      url = `${Store.getters.serviceNowBaseURL}fmt/GetTrucksTrailers?employeeIds=&assetType=${refDataKey}`
      result = await this.getRefDataFromServiceNow(url)
      syncDateTimeManager.setSyncDateTime(refDataKey, { lastSync: currentDateTime, duration: 20160 })
      if (result.length > 0) {
        Store.$app.$storage.set(refDataKey, result, { compressData: true })
      }
      // console.log(`Done Updating the ServiceNow ${refDataKey} Reference Data`)
    }

    if (refDataKey === 'preservatives') {
      url = `${Store.getters.serviceNowBaseURL}fmt/GetPreservativeProducts`
      result = await this.getRefDataFromServiceNow(url)
      syncDateTimeManager.setSyncDateTime(refDataKey, { lastSync: currentDateTime, duration: 20160 })
      if (result.length > 0) {
        Store.$app.$storage.set(this.storageContainers.preservatives, result, { compressData: true })
      }
      // console.log('Done Updating the ServiceNow Preservative Reference Data')
    }
  },
  async getRefDataFromServiceNow (url) {
    const snak = await SnakManager.getSNAK(Store.$app.$storage)

    try {
     const response = await Axios.get(url,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${snak}`,
          },
        },
      )
    return response.data.result
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'FieldVue',
        FeatureName: 'ReferenceDataManager.js - getRefDataFromServiceNow()',
        ErrorCode: error.name,
        ErrorDescription: 'Failed to get ServiceNow Reference Data.',
        AdditionalDetails: `Message: ${error.message}. Stack: ${error.stack}`,
      })
    }
  },
  async getFiscalPeriodsFromServer (year) {
    const apiClient = Axios.create({
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${Store.getters.authToken}`,
          dataType: 'json',
          contentType: 'application/json',
          crossDomain: true,
        },
      })
      const URL = Store.getters.azureProxyBaseURL + `FieldVue/FiscalPeriodsByYr/${year}`

      try {
        if (Store.getters.isOnline) {
          // get an instance of the syncDateTimesManager
          const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
          const result = await apiClient.get(URL)
          // get the current dateTime
          const currentDateTime = DateTime.local()
          syncDateTimeManager.setSyncDateTime('fiscalperiods', { lastSync: currentDateTime, duration: 720 })
          // store fiscal periods
          Store.$app.$storage.set('fiscalPeriods', result.data)
        }
        return Store.$app.$storage.get('fiscalPeriods')
      } catch (error) {
        Store.$app.$azureLogger.$writeLogItem('error', {
          Application: 'FieldVue',
          FeatureName: `ReferenceDataManager.js -> getFiscalPeriodData() -> year: ${year}`,
          ErrorCode: error.name,
          ErrorDescription: error.message,
          AdditionalDetails: 'Failed to get Fiscal Periods Data. Stack: ' + error.stack,
        })
      }
  },
  // async getQCVersionFromServer () {
  //     try {
  //       const response = await Axios.get(`${Store.getters.azureProxyBaseURL}FieldVue/QCVersion`, {
  //             headers: {
  //               Accept: 'application/json',
  //               'Content-Type': 'application/json',
  //               Authorization: `Bearer ${Store.getters.authToken}`,
  //             },
  //         })
  //       // put it in LS
  //       Store.$app.$storage.set(this.storageContainers.qcVersion, JSON.parse(response.data), { compressData: false })
  //       return response
  //     } catch (error) {
  //       Store.$app.$azureLogger.writeLogItem('error', {
  //         Application: 'FieldVue',
  //         FeatureName: 'ReferenceDataManager.js - getQCVersionFromServer()',
  //         ErrorCode: 'Axios Error',
  //         ErrorDescription: 'Failed to get QC version from server.',
  //         AdditionalDetails: `Message: ${typeof error.message === 'object' && error.message !== null ? JSON.stringify(error.message) : error.message}. Stack: ${error.stack}`,
  //       })
  //     }
  //   },
}
