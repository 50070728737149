import Store from '@/store/store'
import { DateTime } from 'luxon'
import common from '@/Lib/common'
import ReferenceDataManager from '@/Lib/ReferenceDataManager.js'
import SyncDateTimesManager from '@/Lib/SyncDateTimesManager'

// main method to use to get reference data
// The assumption made here is that the Reference Data should have been already retrieved from the getAllRefData() or getVolatileRefData() methods.
export const getLSRefData = async (storageContainer, sortField, retryCount = 0, lastError = null) => {
  if (retryCount > 3) throw new Error(lastError)
  try {
    return referenceDataMixin.methods.getDataFromLS(storageContainer, sortField)
  } catch (e) {
    await delay(retryCount)
    // console.log(`retrying to get ref data from LS:  ${storageContainer}`)
    return getLSRefData(storageContainer, sortField, retryCount + 1, e)
  }
}
// main method to use to get fiscal period data
export const getFSPeriodData = async (year, retryCount = 0, lastError = null) => {
  if (retryCount > 3) throw new Error(lastError)
  try {
    return referenceDataMixin.methods.getFiscalPeriodData(year)
  } catch (e) {
    await delay(retryCount)
    return getFSPeriodData(year, retryCount + 1, e)
  }
}
const delay = (retryCount) =>
  new Promise(resolve => setTimeout(resolve, 1000 * retryCount))

export const referenceDataMixin = {
  methods: {
    /**
     * gets all the reference data from the end point
     * and sets the local storage with the results
     * This is used by QCInspections.vue and SafetyAudits.vue
     * The data is only retrieved if the version of QC has changed
     * or it has never been retrieved
    */
     async getAllRefData () {
      return this.getRefData(ReferenceDataManager.storageContainers.all)
    },
    async getCustomerProjectRefData () {
      return this.getRefData(ReferenceDataManager.storageContainers.customerProjectLinks)
    },
    /**
     * gets the reference data that changes often from the end point
     * and sets the local storage with the results
     * This is used by QCInspections.vue and SafetyAudits.vue
     * The data is only retrieved if the version of QC has changed
     * or the timespan has been surpassed, or it has never been retrieved
    */
    async getVolatileRefData () {
      return this.getRefData(ReferenceDataManager.storageContainers.volatile)
    },
    async getJobInfoRefData () {
      return this.getRefData(ReferenceDataManager.storageContainers.jobInfos)
    },
    /**
     * gets a specific type of reference data
     * used by getAllRefData() and getVolatileRefData()
     * new if statement will need to be added to use with future methods
     * consider refactoring to a switch statement if too many refDataTypes
    */
    async getRefData (refDataType) {
      // get the current dateTime
      const currentDateTime = DateTime.local()
      // get the QC version from LS
      // const qcVersion = this.getQCVersion()
      // get the state of the reference data
      const referenceDataNeedsRefresh = await this.referenceDataNeedsRefresh(refDataType, false)

      if (referenceDataNeedsRefresh) {
        // get reference data
        if (refDataType === ReferenceDataManager.storageContainers.volatile) {
          // get the sync date time data for the volatile key
          const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
          const lastSyncDateTimeData = syncDateTimeManager.getSyncDateTime(ReferenceDataManager.storageContainers.volatile)
          await ReferenceDataManager.getReferenceDataFromServer(ReferenceDataManager.storageContainers.volatile, lastSyncDateTimeData.lastSync ? lastSyncDateTimeData.lastSync : currentDateTime.toLocal().toString())
        }
        if (refDataType === ReferenceDataManager.storageContainers.all) {
          await ReferenceDataManager.getReferenceDataFromServer(ReferenceDataManager.storageContainers.all, currentDateTime.toLocal().toString())
        }
        if (refDataType === ReferenceDataManager.storageContainers.jobInfos) {
          await ReferenceDataManager.getReferenceDataFromServer(ReferenceDataManager.storageContainers.jobInfos, currentDateTime.toLocal().toString())
        }
        if (refDataType === ReferenceDataManager.storageContainers.customerProjectLinks) {
          await ReferenceDataManager.getReferenceDataFromServer(ReferenceDataManager.storageContainers.customerProjectLinks, currentDateTime.toLocal().toString())
        }
      } else {
        // update global ref data available state
        Store.dispatch('updateRefDataIsAvailable', true)
      }
    },
    // This method determines whether or not the reference data should be refreshed from the server
    // based on its existence in local storage, it expration state and the QC version
    referenceDataNeedsRefresh (referenceDataKey) {
      // ignore exists check
      const ignoreExistsCheck = [
        ReferenceDataManager.storageContainers.all,
        ReferenceDataManager.storageContainers.volatile,
      ]
      // get the current dateTime
      const currentDateTime = DateTime.local()
      // get an instance of the syncDateTimesManager
      const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
      // get the lastSyncDateTime obj for the requested key
      const lastSyncDateTime = syncDateTimeManager.getSyncDateTime(referenceDataKey)
      // get the current QC Version
      // let qcVersion = ''
      // if (!ignoreQCVersion) {
      //   qcVersion = this.getQCVersion()
      // }
      // determine if the local data exists
      const referenceDataDoesNotExist = !Store.$app.$storage.has(referenceDataKey)
      // determine if the local data is expired
      let localDataExpired = true
      if (lastSyncDateTime) {
        localDataExpired = currentDateTime.diff(DateTime.fromISO(lastSyncDateTime.lastSync), 'minutes').toFormat('m') >= lastSyncDateTime.duration
      }
      // was the QC version changed since the last time the user sync'd the reference data?
      // let refDataKeyQcVersionIsStale = false
      // if (!ignoreQCVersion) {
      //   refDataKeyQcVersionIsStale = qcVersion === false || lastSyncDateTime.qcVersion !== qcVersion
      // }

      if (ignoreExistsCheck.includes(referenceDataKey)) {
        return localDataExpired // || refDataKeyQcVersionIsStale
      } else {
        return referenceDataDoesNotExist || localDataExpired // || refDataKeyQcVersionIsStale
      }
    },
    /**
     * returns an object values from an json objectArray
     *
     * @param {object} context from vue
     * @param { objectArray, pKey } objectArray  is the json object being passed, pKey is the objects key required for usage
    */
    item ({ objectArray, pKey }) {
      let datarray = null
      datarray = Object.keys(objectArray).map(function (i) {
        return objectArray[i][pKey]
      })
      return datarray
    },
    getListOrObject (offlineStorageContainer, field, id) {
      const results = (id || id === 0)
        ? Store.$app.$storage.get(offlineStorageContainer).find(p => p[field] === id)
        : Store.$app.$storage.get(offlineStorageContainer)
      return results
    },
    getLSRefDataByContainerAndKey (offlineStorageContainer, key) {
      const container = Store.$app.$storage.get(offlineStorageContainer)
      let data = null
      if (key) {
        data = this.item({ objectArray: container, pKey: key })
      } else {
        data = Store.$app.$storage.get(offlineStorageContainer)
      }
      const array = [...new Set(data)]
      return array
    },
    getLSRefDataByContainerAndFieldAndId (offlineStorageContainer, field, id) {
      return this.getListOrObject(offlineStorageContainer, field, id)
    },
    async getTaskDefinitions () {
      const taskDefinitions = await getLSRefData(ReferenceDataManager.storageContainers.tasks, 'SortOrder')
      return taskDefinitions
    },
    async getInspectionAndTreatCodes () {
      return getLSRefData(ReferenceDataManager.storageContainers.inspectionAndTreatCodes, 'Code')
    },
    async getPoniVisitStates () {
      return getLSRefData(ReferenceDataManager.storageContainers.visitStates, 'Description')
    },
    async getCustomersAndProjectsSorted () {
      return getLSRefData(ReferenceDataManager.storageContainers.customerProjectLinks, 'CustomerName')
    },
    async getTerritoriesSorted () {
      return getLSRefData(ReferenceDataManager.storageContainers.territories, 'valueArray')
    },
    async getJobNumbersDataSource (contractYear) {
      if (contractYear) {
        const jobInfoData = await getLSRefData(ReferenceDataManager.storageContainers.jobInfos)
        return Object.values(jobInfoData).map(job => job[0]).filter(jobInfo => {
          // Uncomment this block and change the number to the month you want to filter until
          // If current month is January-March, return this year and last year
          //if (DateTime.now().month <= 3) {
            //return jobInfo.ContractYear === contractYear || jobInfo.ContractYear === (contractYear - 1)
          //}
          // Remove the or if you uncomment the above block
          return jobInfo.ContractYear === contractYear || jobInfo.ContractYear === (contractYear - 1)
        })
      } else {
        return getLSRefData(ReferenceDataManager.storageContainers.jobInfos)
      }
    },
    async getPreservatives () {
      return getLSRefData(ReferenceDataManager.storageContainers.preservatives, 'name')
    },
    async getPoniPhotoFailureReasons () {
      return getLSRefData(ReferenceDataManager.storageContainers.failureReasons, 'Description')
    },
    async getServiceCodes () {
      return getLSRefData(ReferenceDataManager.storageContainers.serviceCodes, 'valueArray')
    },
    async getServiceTypes () {
      return getLSRefData(ReferenceDataManager.storageContainers.serviceTypes, 'Description')
    },
    async getSupervisors () {
      return getLSRefData(ReferenceDataManager.storageContainers.supervisors, 'Name')
    },
    async getTrucks () {
      return getLSRefData(ReferenceDataManager.storageContainers.trucks, 'driverLastName')
    },
    async getTrailers () {
      return getLSRefData(ReferenceDataManager.storageContainers.trailers, 'driverLastName')
    },
    async getProfitCenters () {
      return getLSRefData(ReferenceDataManager.storageContainers.profitCenters, 'ShortName')
    },
    getDataFromLS (storageContainer, sortField) {
      let data = false
      const unsortedData = Store.$app.$storage.get(storageContainer)

      if (unsortedData !== null) {
        if (storageContainer === ReferenceDataManager.storageContainers.jobInfos) {
          return unsortedData
        } else if (sortField === 'valueArray') {
          data = unsortedData.sort()
        } else if (sortField === 'SortOrder') {
          data = unsortedData.sort((currentItem, nextItem) => {
            return currentItem[sortField] - nextItem[sortField]
          })
        } else {
          data = unsortedData.sort((currentItem, nextItem) => {
            return currentItem[sortField].localeCompare(nextItem[sortField], undefined, { sensitivity: 'accent' })
          })
        }
      }

      return data
    },
    getForemanEmployeeIdByName (foremanName) {
      const storageContainer = ReferenceDataManager.storageContainers.foremen

      if (storageContainer) {
        const foreman = Store.$app.$storage.get(storageContainer).find(foreman => foreman.Name === foremanName)

        return foreman?.EmployeeId ? foreman.EmployeeId : 'Not Found'
      }
    },
    /**
     *gets the offLineTarget from localStorage
    *
    * @param {object} context from vue
    */
    offLineTarget (inspectionTypeId) {
      const container = ReferenceDataManager.storageContainers.targets
      const res = Store.$app.$storage.get(container).filter(p => p.InspectionTypeId === inspectionTypeId)
      const data = this.item({ objectArray: res, pKey: 'Description' })
      const array = [...new Set(data)]
      return array
    },
    offLineEmployeeNameById (id) {
      const value = this.getLSRefDataByContainerAndFieldAndId(ReferenceDataManager.storageContainers.auditors, 'EmployeeId', id)
      if (value) {
          return value.Name
      } else {
          return id
      }
    },
    offLineProfitCenters (id) {
      return this.getLSRefDataByContainerAndFieldAndId(ReferenceDataManager.storageContainers.profitCenters, id)
    },
    offLineProfitCenterByServiceCode (svcCode) {
      // due to changes to decrease size in local storage, we need to lookup profit center based on service code
      let profitCenter = ''
      const profitCenterData = this.offLineProfitCenters(null)

      if (profitCenterData) {
          profitCenterData.forEach(function (value, i) {
              const pftCtr = value
              if (pftCtr.ServiceCodes.some(p => p === svcCode)) {
                  profitCenter = pftCtr.Name
              }
          })
      }
      return profitCenter
    },
    async getProfitCenters () {
      return this.getDataFromLS(
        ReferenceDataManager.storageContainers.profitCenters,
        'valueArray'
      )
    },
    /**
     *gets the offLineAuditorName from localStorage
    *
    * @param {object} context from vue
    */
    offLineAuditorName () {
      const container = ReferenceDataManager.storageContainers.auditors
      const auditors = Store.$app.$storage.get(container)
      // reconstitue into a local array
      const data = this.item({ objectArray: auditors, pKey: 'Name' })
      // removing duplicates
      const array = [...new Set(data)]
      return array
    },
    /**
     *gets the offLineModels by filtering the models from offLineModel action
    * models are filtered to get the distinct model versions
    *
    * @param {object} context from vue
    * @param { inspectionTypeId, isCurrent } used for filtering
    */
    offLineModel () {
      const container = ReferenceDataManager.storageContainers.models
      const models = Store.$app.$storage.get(container)
      return models
    },
    /**
    * gets the offLineSubject from localStorage
    * values in subject is obtained by combining foreman and audtior
    *
    * @param {object} context from vue
    */
    offlineSubjectNames () {
      const foreman = this.item({ objectArray: Store.$app.$storage.get(ReferenceDataManager.storageContainers.foremen), pKey: 'Name' })
      const auditor = this.item({ objectArray: Store.$app.$storage.get(ReferenceDataManager.storageContainers.auditors), pKey: 'Name' })
      const subject = [...new Set(auditor), ...new Set(foreman)]
      return [...new Set(subject)]
    },
    offLineItemDefinitions (modelId) {
      const container = ReferenceDataManager.storageContainers.itemDefinitions
      const result = Store.$app.$storage.get(container)
      return modelId ? result.filter(p => p.ModelId === modelId) : result
    },
    offLineStatuses ({ id, includeAllStatuses }) {
      const container = ReferenceDataManager.storageContainers.statuses
      let results = null
      if (includeAllStatuses) {
        results = Store.$app.$storage.get(container)
      } else {
          results = Store.$app.$storage.get(container).filter(p => p.Id !== 1)
      }
      results = id ? Store.$app.$storage.get(container).find(p => p.Id === id) : results
      return results
    },
    offLineGroups (modelId) {
      const container = ReferenceDataManager.storageContainers.itemGroupDefinitions
      const groupIds = this.getGroupIdsBymodelId(modelId)
      const groups = Store.$app.$storage.get(container).filter(function (x) {
        return groupIds.includes(x.Id)
      }).sort(function (a, b) { return a.SortOrder - b.SortOrder })
      return groups
    },
    offLineGroupsByGroupType ({ modelId, groupTypeId }) {
      let headerGroups = []
      let groups = []
      if (modelId && modelId > 0) {
        groups = this.offLineGroups(modelId)
      }
      if (groups.length > 0) {
          headerGroups = groups.filter(p => p.ItemGroupTypeId === groupTypeId).sort(function (a, b) { return a.SortOrder - b.SortOrder })
      }
      return headerGroups
    },
    getGroupIdsBymodelId (modelId) {
      const container = ReferenceDataManager.storageContainers.itemGroupDefModel
      const groupIds = modelId ? Store.$app.$storage.get(container).filter(p => p.ModelId === modelId && p.ItemGroupDefId > 0).map(q => q.ItemGroupDefId) : []
      return groupIds
    },
    offLineRatings (ratingId) {
      const container = ReferenceDataManager.storageContainers.ratings
      const results = ratingId ? Store.$app.$storage.get(container).find(p => p.Id === ratingId) : Store.$app.$storage.get(container)
      return results
    },
    offLineRatingTypes ({ versionId, isMqc }) {
      const container = ReferenceDataManager.storageContainers.ratingTypes
      const results = versionId ? Store.$app.$storage.get(container).filter(p => p.Version === versionId && p.IsMQC === isMqc && p.IsMSA === false) : Store.$app.$storage.get(container)
      return results
    },
    offLineModelById (modelId) {
      let result = this.offLineModel()
      result = result.filter(p => p.Id === modelId)
      if (result && result.length > 0) {
          result = result[0]
      } else {
          result = null
      }
      return result
    },
    offLineItemDefinition ({ id, recursive, modelId }) {
      let allItemDefinitions = null
      const container = ReferenceDataManager.storageContainers.itemDataSources
      allItemDefinitions = Store.$app.$storage.get(container)
      let results = null
      if (id && modelId) {
          results = allItemDefinitions.find(p => p.Id === id && p.ModelId === modelId)
      } else if (id) {
          results = allItemDefinitions.find(p => p.Id === id)
      }
      if (!results && id && recursive && recursive === true) {
          const allChildItems = common.flatten(allItemDefinitions.map(p => p.ChildDefs))
          results = allChildItems.find(p => p.Id === id)
      }
      return results
    },
    offLineVersions (id) {
      const container = ReferenceDataManager.storageContainers.versions
      const results = id ? Store.$app.$storage.get(container).find(p => p.Id === id) : Store.$app.$storage.get(container)
      return results
    },
    offLineFieldDataSource ({ itemDefId, modelId }) {
      let results = []
      const dataSourceLocation = this.offLineFieldDataSourceLocation({ itemDefId, modelId })
      const container = ReferenceDataManager.storageContainers.qcDataSourceLocation
      if (dataSourceLocation && dataSourceLocation.length > 0) {
          results = Store.$app.$storage.get(container).filter(p => p.ModelId === modelId && p.ItemDefId === itemDefId)
              .sort(function (a, b) { return a.SortOrder - b.SortOrder })
      }
      return results
    },
    offLineFieldDataSourceLocation ({ itemDefId, modelId }) {
      const container = ReferenceDataManager.storageContainers.itemDataSources
      const results = Store.$app.$storage.get(container).filter(p => p.ModelId === modelId && p.ItemDefId === itemDefId)
      return results
    },
    offLineCustomerNames () {
      const container = ReferenceDataManager.storageContainers.customerProjectLinks
      const customerProjectLinks = Store.$app.$storage.get(container)
      const customers = customerProjectLinks.map(customer => customer.CustomerName)
      return customers || null
    },
    getWeightingFactor (itemId, modelId, modelVersionId, ratingName, IsChildItem, parentItemId, childdefItemId) {
      var container = ReferenceDataManager.storageContainers.itemDefinitions
      const result = Store.$app.$storage.get(container)
      const itemDefId = IsChildItem ? parentItemId : itemId
      let filterRes = itemId ? result.find(p => p.Id === itemDefId && p.ModelId === modelId) : null
      if (IsChildItem) {
        filterRes = filterRes.ChildDefs.find(childDef => childDef.Id === childdefItemId) || null
      }
      let weightingFactorId = (filterRes && filterRes.WeightingFactorId > -1)
            ? this.getWeightingFactorValue(filterRes.WeightingFactorId)
            : 1
        if (modelVersionId >= 6 && (filterRes && filterRes.WeightingFactorId >= 2 && filterRes.WeightingFactorId <= 6)) { // only do this for newer models
            switch (ratingName) {
                case 'Pass':
                case 'C':
                case 'S':
                    weightingFactorId = 1
                    break
            }
        }
      return weightingFactorId
    },
    offlineJobNumberData (jobNumber) {
      try {
        let formattedJobNumber = jobNumber
        if (jobNumber) {
            formattedJobNumber = jobNumber.trim()
            if (formattedJobNumber === '') {
                formattedJobNumber = null
            }
        }
        const container = ReferenceDataManager.storageContainers.jobInfos
        const result = Store.$app.$storage.get(container)
        const filterRes = !jobNumber ? result : result ? result[formattedJobNumber].find(p => p.JobNumber === jobNumber) : null
        return filterRes
      } catch (error) {
        return false
      }
    },
    getWeightingFactorValue (weightingFactorId) {
      const container = ReferenceDataManager.storageContainers.weightingFactors
      const result = Store.$app.$storage.get(container)
      const filterRes = result ? result.find(p => p.Id === weightingFactorId) : null
      return filterRes ? filterRes.Factor : 1
    },
    getOfflineVersions (versionId) {
      return this.getVersions(versionId)
    },
    getVersions (id) {
      const container = ReferenceDataManager.storageContainers.versions
      const result = Store.$app.$storage.get(container)
      const results = (id)
          ? result.find(item => item.Id === id)
          : result

      return results
    },
    /**
    * gets the offLineTarget by id from localStorage
    *
    * @param {object} context from vue
    */
    offLineTargetById (id, inspectionTypeId) {
      const container = ReferenceDataManager.storageContainers.targets
      const results = (id || id === 0)
          ? Store.$app.$storage.get(container).find(p => p.Id === id && p.InspectionTypeId === inspectionTypeId)
          : Store.$app.$storage.get(container)(q => q.InspectionTypeId === inspectionTypeId)
      return results
    },
    // Gets the QC Version from LS
    // getQCVersion () {
    //     const container = ReferenceDataManager.storageContainers.qcVersion
    //     const result = Store.$app.$storage.has(container) ? Store.$app.$storage.get(container) : false
    //     return result
    // },
    // This is a passthrough to avoid the need to include Axios here
    // async getQCVersionFromServer () {
    //   try {
    //     await ReferenceDataManager.getQCVersionFromServer()
    //   } catch (error) {
    //     Store.$app.$azureLogger.writeLogItem('error', {
    //       Application: 'ReferenceDataMixin',
    //       FeatureName: 'getQCVersionFromServer()',
    //       ErrorCode: 'Axios Error',
    //       ErrorDescription: 'Failed to get QC version from server.',
    //       AdditionalDetails: `Message: ${typeof error.message === 'object' && error.message !== null ? JSON.stringify(error.message) : error.message}. Stack: ${error.stack}`,
    //     })
    //   }
    // },
    async getFiscalPeriodData (year) {
      const currentDate = DateTime.now()
      // get an instance of the syncDateTimesManager
      const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
      // get the global lastSyncDateTime obj
      const lastSyncDateTime = syncDateTimeManager.getSyncDateTime('fiscalperiods')
      let fiscalPeriods = {}

      if (lastSyncDateTime === false || currentDate.diff(DateTime.fromISO(lastSyncDateTime.lastSync), 'minutes').toFormat('m') > lastSyncDateTime.duration) {
        // unable to determine last sync date/time
        fiscalPeriods = await ReferenceDataManager.getFiscalPeriodsFromServer(year)
        const periodObject = fiscalPeriods.find((currentPeriodObject) => {
                return (DateTime.fromISO(currentPeriodObject.PeriodBeginDate).startOf('day') <= currentDate.startOf('day') && currentDate.startOf('day') <= DateTime.fromISO(currentPeriodObject.PeriodEndDate).startOf('day'))
              })
        if (!periodObject) {
          // need to get the prior year fiscal periods because the user happen to need periods from the prior year because the day is in close proximity of the beginning of the year.
          // this would primarily be because the date is still within the last period of the previous fiscal year but the current date is in the new year.
          fiscalPeriods = await ReferenceDataManager.getFiscalPeriodsFromServer(year - 1)
        }
      } else {
        fiscalPeriods = Store.$app.$storage.get('fiscalPeriods')
        if (fiscalPeriods) {
          // need to take into account that the fiscal period may end in the following year.
          const periodObject = fiscalPeriods.find((currentPeriodObject) => {
            return (DateTime.fromISO(currentPeriodObject.PeriodBeginDate).startOf('day') <= currentDate.startOf('day') && currentDate.startOf('day') <= DateTime.fromISO(currentPeriodObject.PeriodEndDate).startOf('day'))
          })
          const fiscalPeriodYear = typeof periodObject !== 'undefined' ? new Date(periodObject.PeriodEndDate).getFullYear() : false
          if (fiscalPeriodYear !== year) {
            // fiscal periods are not for the current year
            fiscalPeriods = await ReferenceDataManager.getFiscalPeriodsFromServer(year)
          }
        } else {
          // no fiscal periods found in LS
          fiscalPeriods = await ReferenceDataManager.getFiscalPeriodsFromServer(year)
          const periodObject = fiscalPeriods.find((currentPeriodObject) => {
                return (DateTime.fromISO(currentPeriodObject.PeriodBeginDate).startOf('day') <= currentDate.startOf('day') && currentDate.startOf('day') <= DateTime.fromISO(currentPeriodObject.PeriodEndDate).startOf('day'))
              })
          if (!periodObject) {
            // need to get the prior year fiscal periods because the user happen to need periods from the prior year because the day is in close proximity of the beginning of the year.
            // this would primarily be because the date is still within the last period of the previous fiscal year but the current date is in the new year.
            fiscalPeriods = await ReferenceDataManager.getFiscalPeriodsFromServer(year - 1)
          }
        }
      }
      return fiscalPeriods
    },
    async getReferenceDataFromServiceNow (refDataKey) {
      try {
        // get the state of the reference data
      const referenceDataNeedsRefresh = await this.referenceDataNeedsRefresh(refDataKey, true)

      if (referenceDataNeedsRefresh) {
        return ReferenceDataManager.getReferenceDataFromServiceNow(refDataKey)
      }
      } catch (error) {
        Store.$app.$azureLogger.writeLogItem('error', {
          Application: 'ReferenceDataMixin',
          FeatureName: 'getReferenceDataFromServiceNow()',
          ErrorCode: 'Axios Error',
          ErrorDescription: `Failed to get Reference Data from ServiceNow. -> ${refDataKey}`,
          AdditionalDetails: `Message: ${typeof error.message === 'object' && error.message !== null ? JSON.stringify(error.message) : error.message}. Stack: ${error.stack}`,
        })
      }
    },
   getSubjects () {
      // merge the foreman and auditor lists together
      const auditorContainer = ReferenceDataManager.storageContainers.auditors
      const foremenContainer = ReferenceDataManager.storageContainers.foremen
      const auditors = Store.$app.$storage.get(auditorContainer)
      const foremen = Store.$app.$storage.get(foremenContainer)

      const auditorList = auditors.map(function (x) {
          return {
              EmployeeId: x.EmployeeId,
              Name: x.CrewId.trim() ? x.Name + ' (' + x.CrewId + ')' : x.Name,
              CrewId: x.CrewId ? x.CrewId.trim() : '',
          }
      })

      const foremenList = foremen.map(function (x) {
          return {
              EmployeeId: x.EmployeeId,
              Name: x.CrewId.trim() ? x.Name + ' (' + x.CrewId + ')' : x.Name,
              CrewId: x.CrewId ? x.CrewId.trim() : '',
          }
      })

      let subjects = auditorList.concat(foremenList)

      const result = []
      const map = new Map()
      for (const item of subjects) {
          if (!map.has(item.EmployeeId)) {
              map.set(item.EmployeeId, true) // set any value to Map
              result.push({
                  EmployeeId: item.EmployeeId,
                  Name: item.Name,
                  CrewId: item.CrewId,
              })
          }
      }

      subjects = result.sort(function (a, b) { return (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0) })
      return subjects
    },
  },
}
